import {useState, useEffect, useRef} from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import format from 'date-fns/format';
import differenceInHours from 'date-fns/differenceInHours';
import Form from '@teladoc/pulse/ui/Form';
import Button from '@teladoc/pulse/ui/Button';
import TextInput from '@teladoc/pulse/ui/TextInput';
import Label from '@teladoc/pulse/ui/Label';
import IconCheckDefault from '@teladoc/pulse/icons/check-default.svg';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import {useTranslation, Trans} from 'react-i18next';
import MixpanelUtils from '@livongo/utils/mixpanel';
import ValidationUtils from '@livongo/utils/validation';
import ScreenReader from 'common/ScreenReader';
import {zendeskUrl1, zendeskUrl2} from 'config';
import FormSaveError from '../common/form-save-error/FormSaveError';
import {WebinatoResources} from '../common/WebinatoResources';
import EventModal from './EventModal';
import css from './Programs.scss';

const Programs = () => {
    const tags = {};
    const {t} = useTranslation('lle');
    const formRefWebinar = useRef(null);
    const [loadingEvents, setLoadingEvents] = useState(true);
    const [events, setEvents] = useState([]);
    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [emailUpdatedEventID, setEmailUpdatedEventID] = useState(-1);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [savedError, setSavedError] = useState(null);
    const locale = getStoredLang().toLowerCase();
    const webinarUrl = window.location.hostname;
    const isOneApp = webinarUrl === process.env.ONEAPP_URL;
    const onEditToggle = callback => {
        setShow(showModal => !showModal);

        if (isFunction(callback)) {
            callback();
        }
    };

    const errorDisplay = err => {
        // eslint-disable-next-line no-console
        console.log(err);
    };
    const isNow = (utcUtime, duration) => {
        const toDay = new Date();
        const seminarDate = new Date(utcUtime * 1000);

        return (
            differenceInHours(seminarDate, toDay) <= duration &&
            differenceInHours(seminarDate, toDay) + duration >= 0
        );
    };

    const isFinished = (utcUtime, duration) => {
        const toDay = new Date();
        const seminarDate = new Date(utcUtime * 1000);

        return differenceInHours(seminarDate, toDay) + duration > 0;
    };

    const initWebinar = () => {
        tags.eventInviteeInfo = {};
        // const getEventsObj = {welcomePage: 'welcome'};
        // manipulateResponse(dataTest.data.root.event);
        WebinatoResources.getAllEvents()
            .then(data => {
                manipulateResponse(data.root.event);
                // manipulateResponse(testData.data.root.event);
            })
            .catch(err => errorDisplay(err));
    };

    function manipulateResponse(data) {
        // filter just next filters and active events
        const event1 = filter(data, value => {
            // check if the user is regitered to this event
            const tagInfo = tags.eventInviteeInfo.p_eventID
                ? tags.eventInviteeInfo.p_eventID[0]
                : {};

            if (Object.keys(tagInfo).length !== 0) {
                value.inviteeID = tagInfo.inviteeID;
                value.inviteeEmail = tagInfo.inviteeEmail;
                value.attended = true;
            }

            if (value.eventActive2[0] === '0') return false;

            if (value.future[0] === '0') {
                value.loading = true;

                // check if this is live and not finished
                return (
                    isNow(value.utc_utime[0], value.duration[0]) &&
                    isFinished(value.utc_utime[0], value.duration[0])
                );
            }

            return true;
        });

        setEvents(sortBy(event1, 'utc_utime'));

        setLoadingEvents(false);

        if (events.length > 0) {
            // setSelectedEvent(events[0]);
        }
    }

    const signUpEvent = event => {
        setSelectedEvent(event);
        setShow(true);
        setIsOpen(true);
    };

    // live now link for event
    const getEventLink = event => {
        return `${process.env.WEBINA}/event/${event.p_eventID[0]}`;
    };

    function liveAttend(url) {
        // mixpanel track
        MixpanelUtils.track(
            'Webinar v2.0 - User choose to live attend to an event'
        );
        window.open(url, '_blank');
    }

    function getDate(utcDateTime) {
        const seminarDate = new Date(utcDateTime * 1000);
        const formatDate = format(seminarDate, 'MMM d, hh:mm aaaa');

        return formatDate;
    }

    const updatedEmail = (eventToChange, eventId) => {
        const form = formRefWebinar.current;
        const formValue = form.submit();

        if (!formValue) {
            // mixpanel track
            MixpanelUtils.track(
                'Webinar v2.0 - Error: User email is not set - he cannot sign up'
            );

            return;
        }
        let userEmail = formValue.updatedEmail;

        setSavedError(null);

        if (isUndefined(userEmail)) {
            // mixpanel track
            MixpanelUtils.track(
                'Webinar v2.0 - Error: User email is not set - he cannot sign up'
            );

            return;
        }

        // trim email before sending
        userEmail = userEmail.replace(/\s/g, '');

        const eventToSend = {
            registrantInfo: `<registrants> <registrant><firstName>${
                formValue.firstName || 'there'
            }</firstName><lastName>${
                formValue.lastName || ''
            }</lastName><email>${userEmail}</email></registrant></registrants>`,
            eventInfo: `<events><event><eventID>${eventToChange.eventID[0]}</eventID> <registeredEventDate></registeredEventDate> </event> </events>`,
            sendEmail: 1,
        };

        WebinatoResources.eventRegister(eventToSend)
            .then(data => {
                if (eventToChange.changeEmail) {
                    eventToChange.changeEmail = false;

                    // mixpanel track
                    MixpanelUtils.track(
                        'Webinar v2.0 - User changed his defaut email for an event'
                    );
                } else {
                    eventToChange.loading = false;
                    eventToChange.attended = true;

                    // mixpanel track
                    MixpanelUtils.track(
                        'Webinar v2.0 - User choose to attend to an event'
                    );
                }
                eventToChange.inviteeID = data.root.status[0].inviteeID[0];
                eventToChange.inviteeEmail = data.root.status[0].email[0];
                setShow(false);
                setEmailUpdatedEventID(-1);
            })
            .catch(err => {
                setSavedError(err);
                errorDisplay(err);
                eventToChange.loading = false;
            });
    };

    useEffect(() => {
        initWebinar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={css.row}>
            {!isOneApp && (
                <>
                    <h1 className={css.webinarTitle}> {t('programs.title')}</h1>
                    <p>
                        <Trans
                            i18nKey="programs.subtitle"
                            components={[
                                /* eslint-disable react/jsx-key */
                                <a
                                    href={`${zendeskUrl1}${locale}${zendeskUrl2}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ScreenReader />
                                </a>,
                                /* eslint-enable react/jsx-key */
                            ]}
                        />
                    </p>
                </>
            )}
            {isOneApp && (
                <>
                    {' '}
                    <h1 className={css.webinarTitle}>
                        {' '}
                        {t('programs.oneAppTitle')}
                    </h1>
                    <p>
                        <Trans
                            i18nKey="programs.oneAppSubtitle"
                            components={[
                                /* eslint-disable react/jsx-key */
                                <a
                                    href={`${zendeskUrl1}${locale}${zendeskUrl2}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ScreenReader />
                                </a>,
                                /* eslint-enable react/jsx-key */
                            ]}
                        />
                    </p>
                </>
            )}
            {!loadingEvents && (
                <Form id="webinar" ref={formRefWebinar}>
                    {events.map((event, index) => {
                        return (
                            <div key={index} className={css.webinarEvent}>
                                <span>
                                    {!isOneApp && (
                                        <span>{event.event_name[0]}</span>
                                    )}
                                    {isOneApp && (
                                        <span>
                                            {event.event_name[0].replace(
                                                'Livongo',
                                                'Teladoc Health'
                                            )}
                                        </span>
                                    )}
                                    <br />
                                    {!isNow(
                                        event.utc_utime,
                                        event.duration
                                    ) && (
                                        <span className={css.webinarDate}>
                                            {getDate(event.utc_utime)}
                                        </span>
                                    )}
                                    {isNow(event.utc_utime, event.duration) && (
                                        <span className={css.webinarWeek}>
                                            {t('programs.watchNow')}
                                        </span>
                                    )}
                                </span>
                                <span className={css.webinarButtons}>
                                    {!event.attended &&
                                        !event.loading &&
                                        !isNow(
                                            event.utc_utime,
                                            event.duration
                                        ) && (
                                            <Button
                                                variant="primary"
                                                className={css.button}
                                                onClick={() =>
                                                    signUpEvent(event)
                                                }
                                                aria-label="Sign-Up"
                                            >
                                                {t('programs.signUp')}
                                            </Button>
                                        )}
                                    {isNow(event.utc_utime, event.duration) && (
                                        <Button
                                            className={[
                                                css.button,
                                                css.liveNow,
                                            ]}
                                            variant="primary"
                                            href={getEventLink(event)}
                                            onClick={() =>
                                                liveAttend(getEventLink(event))
                                            }
                                        >
                                            {t('programs.liveNow')} LIVE NOW
                                        </Button>
                                    )}
                                    {event.attended &&
                                        !event.loading &&
                                        !isNow(
                                            event.utc_utime,
                                            event.duration
                                        ) && (
                                            <Button
                                                className={
                                                    css.lvWebinarAttending
                                                }
                                                tabIndex="-1"
                                            >
                                                <span>
                                                    <IconCheckDefault
                                                        className={
                                                            css.iconCheck
                                                        }
                                                    />
                                                </span>
                                                <span>
                                                    {t('programs.attending')}
                                                </span>
                                            </Button>
                                        )}
                                </span>
                                {event.attended &&
                                    emailUpdatedEventID !== event.eventID && (
                                        <p
                                            className={
                                                css.webinarEmailConfirmation
                                            }
                                        >
                                            {savedError && (
                                                <FormSaveError
                                                    error={savedError}
                                                />
                                            )}
                                            <span className={css.webinarDate}>
                                                {t(
                                                    'programs.receiveConfirmation'
                                                )}{' '}
                                                {event.inviteeEmail}.{' '}
                                            </span>
                                            <Button
                                                className={[
                                                    css.webinarDate,
                                                    css.underline,
                                                ]}
                                                onClick={() =>
                                                    setEmailUpdatedEventID(
                                                        event.eventID
                                                    )
                                                }
                                            >
                                                {t('programs.change')}
                                            </Button>
                                        </p>
                                    )}
                                {emailUpdatedEventID === event.eventID &&
                                    event.attended && (
                                        <div>
                                            <p className={css.webinarDate}>
                                                {t('programs.sendConfirmation')}
                                            </p>
                                            {savedError && (
                                                <FormSaveError
                                                    error={savedError}
                                                />
                                            )}
                                            <div>
                                                <div>
                                                    <div
                                                        className={
                                                            css.emailFormQuestion
                                                        }
                                                    >
                                                        <TextInput
                                                            id="updatedEmail"
                                                            name="updatedEmail"
                                                            type="email"
                                                            label={
                                                                <Label>
                                                                    {t(
                                                                        'programs.email'
                                                                    )}
                                                                </Label>
                                                            }
                                                            pattern={
                                                                ValidationUtils.EMAIL
                                                            }
                                                            error={
                                                                <FormElementError>
                                                                    {t(
                                                                        'programs.invalidEmail'
                                                                    )}
                                                                </FormElementError>
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                    <Button
                                                        className={
                                                            css.emailUpdateButton
                                                        }
                                                        type="submit"
                                                        variant="primary"
                                                        onClick={() =>
                                                            updatedEmail(event)
                                                        }
                                                    >
                                                        {t('programs.update')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <HorizontalRule className={css.hr} />
                            </div>
                        );
                    })}
                </Form>
            )}
            {show && (
                <EventModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    event={selectedEvent}
                    show={show}
                    setShow={setShow}
                    onHidden={onEditToggle}
                />
            )}
        </div>
    );
};

export default Programs;
