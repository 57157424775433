import 'scrollingelement';
import 'transitionEnd';
import {render} from 'react-dom';
import PulseApp, {STATUSES} from '@teladoc/pulse/ui/App';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import Arg from '@livongo/arg';
import APIUtils from '@livongo/utils/api';
import MixpanelUtils from '@livongo/utils/mixpanel';
import NewRelicUtils from '@livongo/utilities/system/newrelic';
import i18n, {BUILD_KEY, BUILD_ID, getLanguageCode} from 'i18n';
import Root from 'app/Root';

const lang = getLanguageCode(Arg('locale')) || getStoredLang();

MixpanelUtils.init(process.env.MIXPANEL_TOKEN);
APIUtils.init({
    baseUrl: process.env.API_URL,
    additionalHeaders: {
        'Content-Type': 'application/json',
    },
});

// Update New Relic key values for this repository
const nrObject = {
    ACCOUNT_ID: process.env.ACCOUNT_ID,
    TRUST_KEY: process.env.TRUST_KEY,
    AGENT_ID: process.env.AGENT_ID,
    LICENSE_KEY: process.env.LICENSE_KEY,
    APPLICATION_ID: process.env.APPLICATION_ID,
};

// Initialize New Relic
NewRelicUtils.init({document, nrObject});

// promises will be called in the background, parallel with rendering App component.
const promises = [
    new Promise(resolve => {
        (() => {
            resolve();
        })();
    }),
];

render(
    <PulseApp
        promises={promises}
        build={{
            key: BUILD_KEY,
            value: BUILD_ID,
        }}
        i18n={i18n({lang})}
        render={({status}) => {
            switch (status) {
                case STATUSES.INITIALIZED:
                    return <Root />;
            }
        }}
    />,
    document.getElementById('root')
);
